import { DataObject, DataHandler, getDataObjectConfigById } from 'o365-dataobject';
import IndexedDBHandler from 'o365.pwa.modules.client.IndexedDBHandler.ts';
import { app } from 'o365-modules';
import { FilterObject } from 'o365-filterobject';
import { ref, type Ref } from 'vue';

import type { AppState } from 'o365.pwa.types.ts';
import type { IRequestOptions, RequestOperation } from 'o365-dataobject';
import type { WhereExpression } from 'o365.pwa.modules.shared.dexie.WhereExpression.ts';

declare module 'o365-dataobject' {
    export interface DataObject {
        _shouldEnableOffline: boolean;
        shouldEnableOffline: boolean;
        _shouldGenerateOfflineData: boolean;
        shouldGenerateOfflineData: boolean;
        _jsonDataVersion: number;
        jsonDataVersion: number;
        _pwaAppIdOverride?: string;
        pwaAppIdOverride?: string;
        _pwaDatabaseIdOverride?: string;
        pwaDatabaseIdOverride?: string;
        _pwaObjectStoreIdOverride?: string;
        pwaObjectStoreIdOverride?: string;
        _offline: DataObjectOffline;
        offline: DataObjectOffline;
        enableOffline: () => DataObjectOffline;
        _whereObject: FilterObject;
        whereObject: FilterObject;
        _indexedDbWhereExpression: WhereExpression;
        indexedDbWhereExpression: WhereExpression;
    }
}

declare module 'o365-modules' {
    export interface IDataObjectConfig {
        enableOffline: boolean;
        generateOfflineData: boolean;
        jsonDataVersion: number;
        pwaAppIdOverride?: string;
        pwaDatabaseIdOverride?: string;
        pwaObjectStoreIdOverride?: string;
    }

    export interface IDataObjectFieldConfig {
        pwaIsPrimaryKey?: boolean;
        pwaUseIndex?: boolean;
        pwaIsUnique?: boolean;
        pwaIsMultiValue?: boolean;
        pwaCompoundId?: number;
    }
}

type DataObjectOfflineProperties = {
    shouldEnableOffline: boolean;
    offline: DataObjectOffline;
}

export interface IOfflineRequestOptions {
    skipAbortCheck?: boolean,
    appStateOverride?: AppState
}

export interface IDefaultOfflineRequestOptions {
    skipAbortCheck: boolean,
}

export interface IIndexedDBIndexConfig {
    id: string;
    keyPath: string | Array<string> | null;
    isPrimaryKey: boolean;
    isUnique: boolean;
    isMultiEntry: boolean;
    isAutoIncrement: boolean;
}

const defaultOfflineRequestOptions: IDefaultOfflineRequestOptions = {
    skipAbortCheck: false,
} as const;

Object.defineProperties(DataObject.prototype, {
    shouldEnableOffline: {
        get: function shouldEnableOffline(this: DataObject & DataObjectOfflineProperties): boolean {
            return this._shouldEnableOffline ??= getDataObjectConfigById(this.id)?.enableOffline ?? false;
        },
        set: function shouldEnableOffline(this: DataObject & DataObjectOfflineProperties, value: boolean): void {4
            this._shouldEnableOffline = value;
        }
    },
    shouldGenerateOfflineData: {
        get: function shouldGenerateOfflineData(this: DataObject & DataObjectOfflineProperties): boolean {
            return this._shouldGenerateOfflineData ??= getDataObjectConfigById(this.id)?.generateOfflineData ?? false;
        },
        set: function shouldGenerateOfflineData(this: DataObject & DataObjectOfflineProperties, value: boolean): void {4
            this._shouldGenerateOfflineData = value;
        }
    },
    jsonDataVersion: {
        get: function jsonDataVersion(this: DataObject & DataObjectOfflineProperties): number | undefined {
            return this._jsonDataVersion ??= getDataObjectConfigById(this.id)?.jsonDataVersion ?? -1;
        },
        set: function jsonDataVersion(this: DataObject & DataObjectOfflineProperties, value: number): void {4
            this._jsonDataVersion = value;
        }
    },
    pwaAppIdOverride: {
        get: function pwaAppIdOverride(this: DataObject & DataObjectOfflineProperties): string | undefined {
            return this._pwaAppIdOverride ??= getDataObjectConfigById(this.id)?.pwaAppIdOverride;
        },
        set: function pwaAppIdOverride(this: DataObject & DataObjectOfflineProperties, value: string): void {4
            this._pwaAppIdOverride = value;
        }
    },
    pwaDatabaseIdOverride: {
        get: function pwaDatabaseIdOverride(this: DataObject & DataObjectOfflineProperties): string | undefined {
            return this._pwaDatabaseIdOverride ??= getDataObjectConfigById(this.id)?.pwaDatabaseIdOverride;
        },
        set: function pwaDatabaseIdOverride(this: DataObject & DataObjectOfflineProperties, value: string): void {4
            this._pwaDatabaseIdOverride = value;
        }
    },
    pwaObjectStoreIdOverride: {
        get: function pwaObjectStoreIdOverride(this: DataObject & DataObjectOfflineProperties): string | undefined {
            return this._pwaObjectStoreIdOverride ??= getDataObjectConfigById(this.id)?.pwaObjectStoreIdOverride;
        },
        set: function pwaObjectStoreIdOverride(this: DataObject & DataObjectOfflineProperties, value: string): void {4
            this._pwaObjectStoreIdOverride = value;
        }
    },
    offline: {
        get: function offline(this: DataObject & DataObjectOfflineProperties): DataObjectOffline | null {
            if (this.shouldEnableOffline === false) {
                return null;
            }

            return this._offline ?? new DataObjectOffline(this);
        }
    },
    enableOffline: {
        value: function enableOffline(this: DataObject & DataObjectOfflineProperties) {
            return this.offline;
        }
    },
    whereObject: {
        get: function whereObject(this: DataObject & DataObjectOfflineProperties): FilterObject {
            if (!this._whereObject) {
                this._whereObject = new FilterObject({
                    dataObject: this,
                    columns: this.fields.combinedFields
                });
            }

            return this._whereObject;
        }
    },
    indexedDbWhereExpression: {
        get: function indexedDbWhereExpression(this: DataObject & DataObjectOfflineProperties): WhereExpression {
            return this._indexedDbWhereExpression;
        },
        set: function indexedDbWhereExpression(this: DataObject & DataObjectOfflineProperties, newValue: WhereExpression): void {
            this._indexedDbWhereExpression = newValue;
        }
    }
});

export class DataObjectOffline {
    private dataObject: DataObject;
    private dataHandlerRequest: Function;
    private getOriginalOptions: Function;
    private _hasOfflineChanges: Ref<Boolean> = ref(false);
    public appStateOverride?: AppState = "OFFLINE";

    public readonly shouldGenerateOfflineData: boolean;
    public readonly jsonDataVersion: number;
    public readonly appIdOverride?: string;
    public readonly databaseIdOverride?: string;
    public readonly objectStoreIdOverride?: string;

    get hasOfflineChanges(): Ref<Boolean> {
        return this._hasOfflineChanges;
    }

    get indexedDBIndexes(): Array<IIndexedDBIndexConfig> {
        const dataObjectConfig = getDataObjectConfigById(this.dataObject.id);
        const fields = Array.from(dataObjectConfig?.fields ?? []);

        const indexes = new Array<IIndexedDBIndexConfig>();

        if (this.shouldGenerateOfflineData) {
            indexes.push({
                id: 'PrimKey',
                keyPath: 'PrimKey',
                isUnique: true,
                isAutoIncrement: false,
                isMultiEntry: false,
                isPrimaryKey: true
            }, {
                id: 'O365_Status',
                keyPath: 'O365_Status',
                isUnique: false,
                isAutoIncrement: false,
                isMultiEntry: false,
                isPrimaryKey: false
            });
        }

        for (let i = 0; i < fields.length; i++) {
            const field = fields[i];

            if (!field.pwaUseIndex) {
                continue;
            }

            const index = <IIndexedDBIndexConfig>{
                id: field.name,
                keyPath: field.name,
                isPrimaryKey: !!field.pwaIsPrimaryKey,
                isAutoIncrement: !!field.pwa,
                isUnique: !!field.pwaIsUnique,
                isMultiEntry: !!field.pwaIsMultiValue
            };

            if (field.pwaCompoundId) {
                const keyPath = index.keyPath as string;

                index.keyPath = [keyPath];

                for (let j = fields.length - 1; j > i; j--) {
                    const field2 = fields[j];

                    if (field.pwaCompoundId === field2.pwaCompoundId) {
                        index.id += field2.name;
                        index.keyPath.push(field2.name);

                        fields.splice(j, 1);
                    }
                }
            }

            indexes.push(index);
        }

        return indexes;
    }

    constructor(dataObject: DataObject) {
        this.dataObject = dataObject;

        this.shouldGenerateOfflineData = dataObject.shouldGenerateOfflineData;
        this.jsonDataVersion = dataObject.jsonDataVersion;
        this.appIdOverride = dataObject.pwaAppIdOverride;
        this.databaseIdOverride = dataObject.pwaDatabaseIdOverride;
        this.objectStoreIdOverride = dataObject.pwaObjectStoreIdOverride;
        const dataHandler = dataObject.dataHandler;

        if (!(dataHandler instanceof DataHandler)) {
            throw new Error('At the moment only DataHandler is supported');
        }

        this.dataHandlerRequest = dataHandler.request.bind(dataHandler);
        this.getOriginalOptions = dataObject.recordSource.getOptions.bind(this.dataObject.recordSource);

        dataHandler.request = this.request.bind(this);
        this.dataObject.recordSource.getOptions = this.getOptions.bind(this);

        if (this.shouldGenerateOfflineData) {
            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_PrimKey',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_CCTL',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_Created',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_CreatedBy_ID',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_Updated',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_UpdatedBy_ID',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_JsonData',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_Type',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_ErrorMessage',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_Owner_ID',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_LastCheckIn',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_AppID',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_JsonDataVersion',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_ExternalRef',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_CreatedBy',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_UpdatedBy',
            });

            if (this.dataObject.fields['FileRef'] ?? false) {
                this.dataObject.fields.addFieldIfNotExists({
                    name: 'FileName',
                });

                this.dataObject.fields.addFieldIfNotExists({
                    name: 'FileSize',
                });

                this.dataObject.fields.addFieldIfNotExists({
                    name: 'FileUpdated',
                });

                this.dataObject.fields.addFieldIfNotExists({
                    name: 'FileRef',
                });

                this.dataObject.fields.addFieldIfNotExists({
                    name: 'Extension',
                });

                this.dataObject.fields.addFieldIfNotExists({
                    name: 'CheckedOut',
                });

                this.dataObject.fields.addFieldIfNotExists({
                    name: 'CheckedOutBy_ID',
                });
            }
            this.getOfflineChanges()
        }

    }

    private async request<T extends IRequestOptions>(pType: RequestOperation, pData: T, pHeaders?: Headers, pOptions?: IOfflineRequestOptions) {
        const vHeaders = pHeaders ?? new Headers();
        const vOptions = Object.assign({}, defaultOfflineRequestOptions, pOptions ?? {});

        const idbApp = await IndexedDBHandler.getApp(app.id);
        const idbPwaState = await idbApp?.pwaState;

        if (idbPwaState) {
            const appStateOverride: AppState = vOptions.appStateOverride ?? this.appStateOverride ?? idbPwaState.appState;

            vHeaders.set('O365-App-State-Override', appStateOverride);
        }

        const response = await this.dataHandlerRequest.call(this, pType, pData, vHeaders, pOptions);

        if (pData.operation === "create" || pData.operation === "destroy" || pData.operation === "update") {
            this.getOfflineChanges();
        }
        return response;
    }

    public async getLocalRecordCount () {
        try {
            const dexieInstance = await IndexedDBHandler.getDexieInstance(this.appIdOverride ?? app.id, this.databaseIdOverride ?? "DEFAULT", this.objectStoreIdOverride ?? this.dataObject.id);
            if (!dexieInstance) {
                return;
            }
            let dexieCollection = dexieInstance;
            const recordCount = await dexieCollection.count();
            
            return recordCount;
        } catch(e){
            console.error(e);
        }
        return;
    }

    public async getOfflineChanges() {
        try {
            if(!this.shouldGenerateOfflineData){
                return false;
            }

            let status = "O365_Status";

            const dexieInstance = await IndexedDBHandler.getDexieInstance(this.appIdOverride ?? app.id, this.databaseIdOverride ?? "DEFAULT", this.objectStoreIdOverride ?? this.dataObject.id);
            if (!dexieInstance) {
                return;
            }
            let dexieCollection = dexieInstance;
            let data = await dexieCollection.where(status).anyOf(["UPDATED", "CREATED", "FILE-CREATED", "FILE-UPDATED"]).count();

            this._hasOfflineChanges = ref(data > 0);

            return this._hasOfflineChanges;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    private getOptions() {
        const options = this.getOriginalOptions();

        if (options.filterString && options.filterString === this.dataObject.recordSource.filterString) {
            console.warn(`PWA:: DataObject does not support filter string in PWA mode, use filter object. DataObject ID: ${this.dataObject.id}`);
        }

        if (options.whereClause && options.whereClause === this.dataObject.recordSource.whereClause) {
            console.warn(`PWA:: DataObject does not support where clause in PWA mode, use where object. DataObject ID: ${this.dataObject.id}`);
        }

        delete options.filterString;
        delete options.whereClause;
        delete options.masterDetailString;

        options.dataObjectId = this.dataObject.id;
        options.viewName = this.dataObject.viewName;
        options.uniqueTable = this.dataObject.uniqueTable;

        options.appIdOverride = this.appIdOverride;
        options.databaseIdOverride = this.databaseIdOverride;
        options.objectStoreIdOverride = this.objectStoreIdOverride;

        options.filterObject = this.dataObject.filterObject.filterObject;
        options.whereObject = this.dataObject.whereObject.filterObject;
        options.indexedDbWhereExpression = this.dataObject.indexedDbWhereExpression;
        options.masterDetailObject = this.dataObject.masterDetails.getFilterObject();

        return options;
    }

}

export default DataObjectOffline;
